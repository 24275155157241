@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir';
  src: url('../public/fonts/AvenirLTPro-Light.otf') format('otf'),
       url('../public/fonts/AvenirLTPro-Light.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../public/fonts/AvenirLTPro-Book.otf') format('otf'),
       url('../public/fonts/AvenirLTPro-Book.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../public/fonts/AvenirLTPro-Medium.otf') format('otf'),
       url('../public/fonts/AvenirLTPro-Medium.otf') format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../public/fonts/AvenirLTPro-Heavy.otf') format('otf'),
       url('../public/fonts/AvenirLTPro-Heavy.otf') format('otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../public/fonts/AvenirLTPro-Black.otf') format('otf'),
       url('../public/fonts/AvenirLTPro-Black.otf') format('otf');
  font-weight: 800;
  font-style: normal;
}

body {
  display: flex;
  justify-content: center;
  background: #fafafa;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Ubuntu', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  width: 400px;
  height: 112px;
  border-radius: 6px;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #242d60;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

#checkout {
  width: 100vw;
}

.bg {
  background-color: #007bff; /* Replace 'primary-col' with a real color */
  width: 480px;
  overflow: hidden;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px;
  font-family: 'Roboto';
  margin-top: 40px;
}

.card {
  background-color: white;
  width: 100%;
  float: left;
  margin-top: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 80px 30px 25px 30px;
  text-align: center;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* Fixed nested styles for the success state */
.card__success {
  position: absolute;
  top: -50px;
  left: 145px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #60c878;
  border: 5px solid white;
}

.card__success i {
  color: white;
  line-height: 100px;
  font-size: 45px;
}

.card__msg {
  text-transform: uppercase;
  color: #55585b;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.card__submsg {
  color: #959a9e;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0px;
}

.card__body {
  background-color: #f8f6f6;
  border-radius: 4px;
  width: 100%;
  margin-top: 30px;
  float: left;
  box-sizing: border-box;
  padding: 30px;
}

.card__avatar {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 7px;
}

.card__recipient-info {
  display: inline-block;
}

.card__recipient {
  color: #232528;
  text-align: left;
  margin-bottom: 5px;
  font-weight: 600;
}

.card__email {
  color: #838890;
  text-align: left;
  margin-top: 0px;
}

.card__price {
  color: #232528;
  font-size: 70px;
  margin-top: 25px;
  margin-bottom: 30px;
}

.card__price span {
  font-size: 60%;
}

.card__method {
  color: #d3cece;
  text-transform: uppercase;
  text-align: left;
  font-size: 11px;
  margin-bottom: 5px;
}

.card__payment {
  background-color: white;
  border-radius: 4px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__credit-card {
  width: 50px;
  display: inline-block;
  margin-right: 15px;
}

.card__card-details {
  display: inline-block;
  text-align: left;
}

.card__card-type {
  text-transform: uppercase;
  color: #232528;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 3px;
}

.card__card-number {
  color: #838890;
  font-size: 12px;
  margin-top: 0px;
}

.card__tags {
  clear: both;
  padding-top: 15px;
}

.card__tag {
  text-transform: uppercase;
  background-color: #f8f6f6;
  box-sizing: border-box;
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 10px;
  color: #d3cece;
}

.ProductSummary-name {
  position: relative;
}

.ProductSummary-name::after {
  content: 'New Text'; /* Replace with your desired text */
  position: absolute;
  top: 0;
  left: 0;
  color: inherit; /* Match the original text color */
  font-size: inherit; /* Match the original font size */
  font-weight: inherit; /* Match the original font weight */
  /* Add any additional styles if needed */
}

#loom-companion-mv3 {
  display: none;
  position: absolute;
}

/* .App-Overview {
  display: none;
} */